<template>
  <div class="success-background">
    <div class="success-contaion">
      <div class="success-title"> 恭喜注册成功！</div>
      <div class="success-use">请登录易享家APP使用</div>
      <div class="success-introduce">
        使用IOS系统的用户可以在App Store中搜索“易享家”并下载；使用Android系统的用户可以在在各大安卓市场中搜索“易享家”并下载。
      </div>
      <div class="apple-android">
        <van-image class="apple" width="160" height="48" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assetssuccess/apple.png" @click="download(1)"/>
        <van-image class="android" width="160" height="48" src="https://img1.clozhome.com/new-clozhome/mini/app_page_assetssuccess/android.png" @click="download(2)"/>
      </div>
    </div>
  </div>
</template>
<script>
  import CallApp from 'callapp-lib';


  export default {
    name: 'Success',
    data() {
      return {

      }
    },
    created() {

      const options = {
        scheme: {
          protocol:'android://clozhome/open',   //打开本地 apk
        },
        //部署到universal域名下，当前域名跨域请求到universal地址
        // universal: {
        //   host: 'applinks:activity.clozhome.com'
        // },
        timeout: 3000,
        appstore: 'https://itunes.apple.com/cn/app/zi-he-xin/id581817870?mt=8',//ios 跳转 appstore地址
        yingyongbao: 'https://a.app.qq.com/o/simple.jsp?pkgname=ctrip.android.view&fromcase=40002',//android 跳转应用宝地址
        fallback: 'https://a.app.qq.com/o/simple.jsp?pkgname=ctrip.android.view&fromcase=40002',//唤起应用失败，未安装时跳转到应用宝地址
      };
      const callLib = new CallApp(options);
      callLib.open({path:''})
    },
    methods: {
      download(tag) {
        if (tag ==1) {
          //ios
          location.href = 'https://itunes.apple.com/cn/app/zi-he-xin/id581817870?mt=8'
        }  else {
          //android
          location.href = 'http://img1.clozhome.com/package/clozhome_release.apk'
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
  .success-background {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    top: 0;
    left: 0;
    background: url("https://img1.clozhome.com/new-clozhome/mini/app_page_assetssuccess/success.png") no-repeat;
    background-size: 100% 100%;
  }
  .success-contaion {
    padding: 0 35px;
    padding-top: 50px;
  }
  .success-title {
    font-size: 32px;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 600;
  }
  .success-use {
    font-size: 24px;
    text-align: center;
    margin-top: 24px;
    letter-spacing: 1px;
    font-weight: 600;
  }
  .success-introduce {
    margin-top: 20px;
    font-size: 12px;
    color: #666666;
    letter-spacing: 1px;
  }
  .apple-android {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .android{
    margin-top: 33px;
  }
</style>
